<template>
    <div class=" ">
        <el-card>
            <div slot="header" class="card_title">
                <span>公告新增</span>
            </div>
            <div class="add_main hidden_scroll">
                <span class="font_weight_bold">公告信息</span>
                <div class="margin_top_20">
                    <el-form label-position="right" :rules="rule" ref="formData" label-width="120px" :model="formData">
                        <el-form-item label="公告标题" prop="title" class="margin_top_20">
                            <el-input v-model="formData.title" placeholder=""
                                :disabled="$route.query.type=='detail'"></el-input>
                        </el-form-item>


                        <el-form-item label="描述" prop="desc">
                            <el-input type="textarea" :rows="8" v-model="formData.desc"
                                :disabled="$route.query.type=='detail'"></el-input>
                        </el-form-item>
                        <el-form-item label="是否显示" prop="status">
                            <el-radio v-model="formData.status" label="1"
                                :disabled="$route.query.type=='detail'">是</el-radio>
                            <el-radio v-model="formData.status" label="0"
                                :disabled="$route.query.type=='detail'">否</el-radio>
                        </el-form-item>
                        <el-form-item label="语言" prop="language">
                            <el-select v-model="formData.language" placeholder="请选择语言"
                                :disabled="$route.query.type=='detail'">
                                <el-option label="简体中文" value="1">
                                </el-option>
                                <el-option label="英语" value="2">
                                </el-option>
                                <!-- <el-option label="日语" value="3">
                                </el-option>
                                <el-option label="繁体中文" value="4">
                                </el-option> -->
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <div class="flex justify_content_c align_items_c">
                                <el-button type="primary" class="custom_btn_width_100" @click="submit('formData')"
                                    v-if="$route.query.type!='detail'">保存</el-button>
                                <el-button class="custom_btn_width_100 " @click="$router.back()">返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import { validateNull } from "@/utils/validate";
    import { createnotice, detailsnotice, updatenotice } from "@/api/article";
    export default {

        name: '',
        data() {
            return {
                rule: {
                    title: [{ validator: validateNull, trigger: "blur", required: true }],
                    desc: [{ validator: validateNull, trigger: "blur", required: true }],
                    status: [{ validator: validateNull, trigger: "change", required: true }],
                    language: [{ validator: validateNull, trigger: "change", required: true }],
                },
                value: "",
                formData: {
                    title: '',
                    desc: '',
                    status: '',
                    language: ''
                }

            }
        },
        created() {
            if (!this.$route.query.id) {
                this.clearFormAdd()
            } else {
                this.detailsnotice()
            }

        },
        methods: {
            submit(formName) {

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const that = this;
                        if (this.$route.query.type == "edit") {
                            updatenotice(that.formData).then((res) => {
                                if (res.code == 200) {
                                    that.$router.push({ path: "/message/announcement" })
                                } else {
                                }
                            }).catch((error) => {
                            });
                        } else {
                            createnotice(that.formData).then((res) => {
                                if (res.code == 200) {
                                    that.$router.push({ path: "/message/announcement" })
                                } else {
                                }
                            }).catch((error) => {
                            });
                        }


                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
            detailsnotice() {
                const that = this;
                detailsnotice({ d: that.$route.query.id }).then((res) => {
                    if (res.code == 200) {
                        that.formData = res.data
                        that.formData.status = that.formData.status.toString();
                        that.formData.language = that.formData.language.toString()
                    } else {
                        that.$message.error(res.message);
                    }
                }).catch((error) => {
                    that.$message.error(error.message);
                });
            },
            clearFormAdd() {
                for (let key in this.formData) {
                    this.formData[key] = "";
                }
            },
        }

    }
</script>

<style scoped>
    .main {
        width: calc(100% - 40px);
    }

    .add_main {
        width: 70%;
        height: 81vh;
        overflow-y: scroll;
        margin: 0 auto;
    }
</style>